import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';

export const handleCustomerImageError = (event) => {
  event.target.src = '/images/customer_logos/unknown.png';
};

export const fetchData = async (endpoint) => {
  try {
    const { data } = await axios.get(`/api/${endpoint}/`);
    console.log('Fetched data:', data);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 301) {
      console.log('Redirecting to login page');
      window.location.href = '/api/microsoft/auth-redirect/';
    } else {
      console.error('Error fetching data:', error);
    }
    return null; // Return null or handle it as needed
  }
};

export const currentDateDMY = new Date().toLocaleDateString('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
}).replace(/\//g, '-');

export const getCustomerImageName = (orgName) => {
  const orgNameLower = orgName.toLowerCase();
  if (orgNameLower.startsWith('bentley')) {
    return 'bentley.png';
  } else if (orgNameLower.startsWith('seequent')) {
    return 'seequent.png';
  } else {
    const firstWord = orgNameLower.split(' ')[0];    
    return `${firstWord}.png`;
  }
};

export const useTableData = (endpoint) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [value, setValue] = useState(0);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await fetchData(endpoint);
        if (result) {
          setData(result);
        } else {
          setError('Failed to fetch data');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchDataAsync();
  }, [endpoint]);

  return { data, setData, error, value, setValue };
};

// Adjust column widths based on content
export const useAdjustedColumns = (columns, data) => useMemo(() => {
  return columns.map((column, index) => {
    const maxLength = data.reduce((max, row) => {
      const cellValue = row[column.field]?.toString() || '';
      return Math.max(max, cellValue.length);
    }, 0);

    let estimatedWidth;
    if (index === 0) {
      estimatedWidth = maxLength * 10;
    } else {
      estimatedWidth = maxLength * 10 + 30;
    }

    return { ...column, width: estimatedWidth };
  });
}, [data, columns]);

// Handle tab change
export const createHandleChange = (setValue) => (event, newValue) => {
  setValue(newValue);
};

// Set dynamic page size based on data length
export const useDynamicPageSize = (data) => useMemo(() => data.length < 50 ? data.length : 50, [data]);

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null; // or return ''; if you prefer to return an empty string when the cookie is not found
};

export const getPermissionCookieValue = () => {
  const permissionString = getCookie('user_permissions');
  return permissionString.replace(/[\[\]"]/g, '');
};

export const canEditField = (permissions, level) => {
  // returns editable prop value in accordance with the hirarchy of permissions
  if (level === 'cloudoperation') {
    return (permissions.includes('pds_backend_service.cloudoperation')) ? 'always' : 'never';
  }
  if (level === 'licensing') {
    return (permissions.includes('pds_backend_service.cloudoperation') || (permissions.includes('pds_backend_service.licensing'))) ? 'always' : 'never';
  }
  if (level === 'permissioned') {
    return (permissions.includes('pds_backend_service.cloudoperation') || (permissions.includes('pds_backend_service.licensing')) || (permissions.includes('pds_backend_service.permissioned'))) ? 'always' : 'never';
  }
  return 'never';
};
