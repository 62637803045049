import React, { useMemo, useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import axios from 'axios';
import { getCookie } from './commonTableFunctions';
import { IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const checkforComponent = (name, optionals) => {
  if (optionals.length > 0) {
    for (const item of optionals) {
      if (Array.isArray(item) && item.includes(name)) {
        return [true, item[item.length - 1]];
      }
      if (item === name) {
        return [true, null];
      }
    }
  }
  return [false, null];
};

export const useAdjustedColumns = (columns = [], data = []) => useMemo(() => {
  if (!Array.isArray(columns)) {
    console.error('Columns is not an array:', columns);
    columns = [];
  }
  if (!Array.isArray(data)) {
    console.error('Data is not an array:', data);
    data = [];
  }
  return columns.map((column, index) => {
    const maxLength = data.reduce((max, row) => {
      const cellValue = row[column.field]?.toString() || '';
      return Math.max(max, cellValue.length);
    }, 0);

    let estimatedWidth;
    if (index === 0) {
      estimatedWidth = maxLength * 10;
    } else {
      estimatedWidth = maxLength * 10 + 30;
    }

    return { ...column, width: estimatedWidth };
  });
}, [data, columns]);

export const RenderTable = ({ columns, data = [], setData, title, setisEditing, currentDateDMY, optionals = [] }) => {
  const [loading, setLoading] = useState(true);
  const adjustedColumns = useAdjustedColumns(columns, data);
  const dynamicPageSize = useMemo(() => (data.length < 50 ? data.length : 50), [data]);

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  const updateData = async (newData, column) => {
    const csrfToken = getCookie('csrftoken');
    if (!csrfToken) {
      console.error('CSRF token not found in cookies');
      return false; // Exit the function if the CSRF token is not found
    }
    try {
      const requestBody = {
        ...newData,
        column: column
      };
      const apiPath = `/api${window.location.pathname}/`;
      const response = await axios.put(apiPath, requestBody, {
        headers: {
          'X-CSRFToken': csrfToken,
        }
      });
      if (response.status === 200) {
        return true;
      } else {
        console.error('Error updating data:', response);
        return false;
      }
    } catch (error) {
      console.error('Error updating data:', error);
      return false;
    }
  };

  const fetchAllData = async () => {
    const csrfToken = getCookie('csrftoken');
    if (!csrfToken) {
      console.error('CSRF token not found in cookies');
      return []; // Return an empty array if the CSRF token is not found
    }
    try {
      const apiPath = `/api${window.location.pathname}/`;
      const response = await axios.get(apiPath, {
        headers: {
          'X-CSRFToken': csrfToken,
        }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Error fetching all data:', response);
        return [];
      }
    } catch (error) {
      console.error('Error fetching all data:', error);
      return [];
    }
  };

  const [Editing, setEditing] = useState(false);

  const handleEdit = () => {
    setisEditing(!Editing);
    setEditing(!Editing);
  };

  const [add, AddComponent] = checkforComponent('enableAdding', optionals);
  const [edit, EditComponent] = checkforComponent('enableEditing', optionals);

  return (
    <>
      <MaterialTable
        title={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{title}</span>
            {edit && (
              <>
                <span style={{ marginLeft: '30px', marginTop: '8px' }}>
                  <IconButton onClick={handleEdit} size="large">
                    {Editing ? <LockOpenIcon /> : <LockIcon />}
                  </IconButton>
                </span>
                <span style={{ marginTop: '5px', color: 'rgba(0, 0, 0, 0.54)', fontWeight: 'bold' }}>
                  {Editing ? 'Editing Mode' : 'Viewing Only'}
                </span>
              </>
            )}
            {add && (
              <span style={{ marginTop: '5px', marginLeft: '25px' }}>
                <AddComponent setData={setData} />
              </span>
            )}
          </div>
        }
        data={data}
        columns={adjustedColumns}
        options={{
          pageSize: dynamicPageSize,
          headerStyle: {
            backgroundColor: '#4A4A4C',
            color: '#FFF'
          },
          rowStyle: {
            height: '30px',
            padding: '0px 5px',
          },
          exportMenu: [
            {
              label: 'Export CSV (All Data)',
              exportFunc: async () => {
                const allData = await fetchAllData(); // Fetch all data
                ExportCsv(adjustedColumns, allData, 'PDS-Export_AllData_' + currentDateDMY);
              }
            },
            {
              label: 'Export PDF (All Data)',
              exportFunc: async () => {
                const allData = await fetchAllData(); // Fetch all data
                ExportPdf(adjustedColumns, allData, 'PDS-Export_AllData_' + currentDateDMY);
              }
            },
            {
              label: 'Export CSV (Visible Data Only)',
              exportFunc: (cols, datas) => ExportCsv(cols, datas, 'PDS-Export_VisibleData_' + currentDateDMY)
            },
            {
              label: 'Export PDF (Visible Data Only)',
              exportFunc: (cols, datas) => ExportPdf(cols, datas, 'PDS-Export_VisibleData_' + currentDateDMY)
            }
          ]
        }}
        localization={{
          body: {
            emptyDataSourceMessage: loading ? 'Loading data...' : 'No records to display',
          },
        }}
        editable={
          edit && Editing && {
            onRowUpdate: (newData, oldData) => {
              return new Promise(async (resolve, reject) => {
                const dataUpdate = [...data];
                const updatedColumns = Object.keys(newData).filter(key => newData[key] !== oldData[key]);
                const updateSuccess = await updateData(newData, updatedColumns); // This function should handle updating the data state or making an API call
                if (updateSuccess) {
                  // Caching the table data as dataUpdate so fetching is not required.
                  const id_value = oldData.tableData.id;
                  const index = dataUpdate.findIndex(row => row.id === id_value);
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                } else {
                  reject();
                }
              });
            }
          }
        }
      />
    </>
  );
};
