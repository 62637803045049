import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { handleCustomerImageError, fetchData, createHandleChange, useTableData, currentDateDMY, getCustomerImageName } from './commonTableFunctions';
import { RenderTable } from './RenderTable';
import NavTabs from './NavTabs';

const data = await fetchData('bentley_id_data');

const BentleyidTable = () => {
  const { data, setData, error, value, setValue } = useTableData('bentley_id_data');
  const handleChange = useMemo(() => createHandleChange(setValue), [setValue]);
  const [isEditing, setisEditing] = useState(false);
  const columns = useMemo(() => [
    {
      title: 'Org Name',
      field: 'org_name',
      cellStyle: { backgroundColor: '#1D1F23', color: '#FFF' },
      render: rowData => (
        <div style={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
          <img src={`/images/customer_logos/${getCustomerImageName(rowData.org_name)}`} onError={handleCustomerImageError} alt={rowData.org_name} style={{ marginRight: 10, width: 30, height: 30 }} />
          {rowData.org_name}
        </div>
      ),
      editable: 'never'
    },
    { title: 'Org ID', field: 'org_id', editable: 'never' },
    { title: 'Type', field: 'type', editable: 'never' },
    { title: 'Source Name', field: 'source_name', editable: 'never' },
    { title: 'Environment Name', field: 'environment_name', editable: 'never' },
    { title: 'Created At', field: 'created_at', type: 'datetime', editable: 'never' },
    { title: 'Modified At', field: 'modified_at', type: 'datetime', editable: 'never' },
  ], []);

  useEffect(() => {
    if (isEditing === false) {
      const intervalId = setInterval(async () => {
        const newData = await fetchData('bentley_id_data');
        setData(newData);
      }, 60000); // Fetch data every 60 seconds
      return () => clearInterval(intervalId);
    }
  });

  return(
    <>
      <NavTabs value={value} handleChange={handleChange} />
      <RenderTable
        columns={columns}
        data={data}
        setData={setData}
        title="Bentley ID Production Organization Data"
        setisEditing={setisEditing}
        currentDateDMY={currentDateDMY}
      />;
    </>
  );
};

export default BentleyidTable;
