
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { fetchData, createHandleChange, useTableData, currentDateDMY } from './commonTableFunctions';
import { RenderTable } from './RenderTable';
import { Select, MenuItem, FormControl, TextField } from '@mui/material';
import NavTabs from './NavTabs';
import { AddNewInstance } from './AddNewContent';
import { getPermissionCookieValue, canEditField } from './commonTableFunctions';

const data = await fetchData('central_instance_data');

const CentralInstanceTable = () => {
  const { data, setData, error, value, setValue } = useTableData('central_instance_data');
  const handleChange = useMemo(() => createHandleChange(setValue), [setValue]);

  const [isEditing, setisEditing] = useState(false);

  const permissions = getPermissionCookieValue();

  const columns = useMemo(() => [
    {
      title: 'Organisation as per Manage',
      field: 'Organisation as per Manage',
      cellStyle: { backgroundColor: '#1D1F23', color: '#FFF' },
      editable: canEditField(permissions, 'cloudoperation'),
      editComponent: (props) => (
        // Ensures the text field is styled correctly
        <TextField
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        InputProps={{
          style: { color: '#FFF', backgroundColor: '#1D1F23' }
        }}
        sx={{
           // Removes the border when the field is not being edited
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
      />
      )
    },
    { title: 'Type', 
      field: 'Instance Type', 
      editable: canEditField(permissions, 'licensing')
    },
    { title: 'Status', 
      field: 'Status',
      editable: canEditField(permissions, 'cloudoperation'),
      editComponent: (props) => (
      <FormControl fullWidth>
        <Select
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Comissioning">Comissioning</MenuItem>
          <MenuItem value="Decommissioned">Decommissioned</MenuItem>
          <MenuItem value="On Hold">On Hold</MenuItem>
          <MenuItem value="Migrated">Migrated</MenuItem>
        </Select>
      </FormControl>
      )
    },
    { title: 'Instance Name', 
      field: 'Instance Name', 
      editable: canEditField(permissions, 'cloudoperation') 
    },
    { title: 'Instance Start', 
      field: 'Instance Start', 
      type: 'datetime',
      editable: canEditField(permissions, 'cloudoperation')
    },
    { title: 'Azure Region', 
      field: 'Azure Region', 
      editable: canEditField(permissions, 'cloudoperation')
    },
    { title: 'K8s Cluster',
      field: 'K8s Cluster', 
      editable: canEditField(permissions, 'cloudoperation')
    },
    { title: 'PG Database', 
      field: 'PG Database',
      editable: canEditField(permissions, 'cloudoperation')
    },
    { title: 'Operational Comments',
      field: 'Operational Comments',
      editable: canEditField(permissions, 'licensing')
    },
    { title: 'URL', field: 'URL', editable: 'never' },
    { title: 'Seequent Contact', 
      field: 'Seequent Contact',
      editable: canEditField(permissions, 'permissioned')
    },
    { title: 'Seequent Contact Email', 
      field: 'Seequent Contact Email',
      editable: canEditField(permissions, 'permissioned')
    },
    { title: 'Primary Company Contact Name', 
      field: 'Primary Company Contact Name',
      editable: canEditField(permissions, 'permissioned')
    },
    { title: 'Primary Company Contact Email', 
      field: 'Primary Company Contact Email',
      editable: canEditField(permissions, 'permissioned')
    }
  ], []);

  useEffect(() => {
    if (isEditing === false) {
      const intervalId = setInterval(async () => {
        const newData = await fetchData('central_instance_data');
        setData(newData);
      }, 60000);
  
      return () => clearInterval(intervalId);
    }
  });

  const optionals = [];
  // checking for the lowest level of permission to add optional features
  optionals.push(['enableAdding', AddNewInstance]);
  if (canEditField(permissions, 'permissioned') === 'always') {
    optionals.push('enableEditing');
  }

  return (
    <>
      <NavTabs value={value} handleChange={handleChange} />
      <RenderTable
        columns={columns}
        data={data}
        setData={setData}
        title="Central Instances Data"
        setisEditing={setisEditing}
        currentDateDMY={currentDateDMY}
        optionals={optionals}
      />
    </>
  );
};

export default CentralInstanceTable;
